import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import Select from "@atoms/input/input-select";
import { Input } from "@atoms/input/input-text";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseBold, InfoSmall, InfoSmallBold } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { useProduct } from "@features/products/state/use-product";
import { useProducts } from "@features/products/state/use-products";
import { ProductType } from "@features/products/types";
import { formatAmount } from "@features/utils/format/strings";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

type RegulStockModalType = {
  open: boolean;
  product?: ProductType;
};

export const RegulStockModalAtom = atom<RegulStockModalType>({
  key: "RegulStockModal",
  default: {
    open: false,
  },
});

export const RegulStockModal = () => {
  const [regulStockModal, setRegulStockModal] =
    useRecoilState(RegulStockModalAtom);

  const { ean } = useParams<{ ean: string }>();
  const { refresh } = useProduct(ean || "", undefined, undefined, true);

  const { regulStock } = useProducts();
  const { current } = useShopLocations();

  const [motif, setMotif] = useState("");
  const [lib, setLib] = useState("");
  const [newStock, setNewStock] = useState(0);

  const [motifList, setMotifList] = useState<{ lib: string; code: string }[]>(
    []
  );

  useControlledEffect(() => {
    if (regulStockModal.product) setNewStock(regulStockModal.product.qteStock);
    else setNewStock(0);
  }, [regulStockModal.product]);

  const handleClose = () => {
    setMotif("");
    setLib("");
    setRegulStockModal({ open: false, product: undefined });
  };

  return (
    <Modal
      className="sm:max-w-[500px]"
      open={regulStockModal.open}
      onClose={() => handleClose()}
    >
      {regulStockModal.product && (
        <ModalContent title="Régularisation de stock">
          <div className="flex gap-2 mb-4">
            <InfoSmallBold className="flex flex-col justify-center h-full ">
              {regulStockModal.product.designation}
            </InfoSmallBold>
            <InfoSmallBold> - </InfoSmallBold>
            <InfoSmall className="flex flex-col justify-center h-full">
              {regulStockModal.product.id}
            </InfoSmall>
          </div>
          <Frame className="grow flex flex-col gap-4">
            <div className="flex gap-2 items-center justify-between">
              <Base className="w-full">Motif</Base>
              <div className="w-full flex justify-start ">
                <Select
                  className="max-w-64"
                  onChange={(e) => setMotif(e.target.value)}
                  onClick={async () => {
                    if (motifList.length === 0) {
                      const res = await CommonApiClient.getRegulStockMotifs();
                      if (res) setMotifList(res);
                    }
                  }}
                >
                  <option value={""}>--</option>
                  {motifList.map((motif) => (
                    <option value={motif.code}>{motif.lib}</option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Base className="w-full">Libellé</Base>
              <div className="w-full flex justify-start ">
                <Input
                  className="max-w-64"
                  value={lib}
                  onChange={(e) => setLib(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Base className="w-full">
                Stock avant: {regulStockModal.product.qteStock}
              </Base>
              <Base className="w-full">
                {`
                PAMP avant: 
                ${formatAmount(
                  regulStockModal.product.pampNetHT,
                  current?.devisSymb || "€",
                  current?.devisNbDecim || 2
                )}`}
              </Base>
            </div>
            <div className="flex gap-2 items-center justify-between">
              <div className="w-full flex justify-between items-center">
                <div className="w-full flex justify-start items-center gap-4 ">
                  <BaseBold>Nouveau stock</BaseBold>
                  <ArrowRightIcon className="w-6 h-6" />
                </div>

                <div className="w-full">
                  <div className="max-w-40">
                    <InputCounter
                      min={-999}
                      value={newStock}
                      onChange={(newValue) => setNewStock(newValue)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Frame>
          <div className="w-full flex gap-4 justify-center mt-4">
            <Button theme="danger">Quitter</Button>
            <Button
              theme="valid"
              shortcut={["enter"]}
              disabled={motif === ""}
              onClick={async () => {
                if (regulStockModal.product && current) {
                  const res = await regulStock(
                    regulStockModal.product,
                    current.codeLieu,
                    newStock,
                    motif,
                    `${
                      motifList.find((m) => m.code === motif)?.lib ||
                      "Libellé inconnu"
                    } ${lib ? `- ${lib}` : " "}`
                  );
                  if (res) {
                    await refresh();
                    handleClose();
                  }
                }
              }}
            >
              Valider
            </Button>
          </div>
        </ModalContent>
      )}
    </Modal>
  );
};
