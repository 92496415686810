import { Button } from "@atoms/button/button";
import { XIcon } from "@heroicons/react/outline";
import { ReactNode, useState, useEffect } from "react";

const BottomPopUp = (props: { children: ReactNode; className?: string }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMounted, setIsMounted] = useState(false); // État pour gérer l’animation

  const handleClose = () => {
    setIsMounted(false); // Déclenche l'animation de fermeture
    setTimeout(() => setIsVisible(false), 300); // Cache complètement après l'animation
  };

  useEffect(() => {
    // Déclenche l'animation d’apparition au montage
    setIsMounted(true);
  }, []);

  if (!isVisible) return null;

  return (
    <div
      className={`fixed bottom-2 mx-4 left-0 right-0 bg-slate-100 border-2 dark:bg-slate-800 dark:border-slate-600 rounded-lg p-4 text-center z-60 transition-transform duration-300 ease-in-out transform ${
        isMounted ? "translate-y-0" : "translate-y-full"
      } ${props.className}`}
    >
      {props.children}
      <div className="absolute top-0 right-0 pt-4 pr-4">
        <Button
          theme="danger"
          className="!bg-slate-300 !h-7 !w-7 border-0 dark:!bg-slate-600 !rounded-full text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none"
          size="sm"
          onClick={handleClose}
          icon={(p) => <XIcon {...p} />}
        />
      </div>
    </div>
  );
};

export default BottomPopUp;
