import { ROUTES } from "@features/routes";
import { Modals } from "@views/modals";
import { Portals } from "@views/portals";
import { Outlet, Route } from "react-router-dom";
import { Header } from "../_layout/header";
import { HomePage } from "./home";
import { ProductsPage } from "./products";
import { ProductPage } from "./products/product";
import { LocationsPage } from "./select-locations";
import { BasketPage } from "./products/baskets";
import { ScanBox, ScanTriggerIcon } from "@views/_layout/scan";
import { CommandePage } from "./products/client-order";
import { NotAvailablePage } from "./not-available";
import { ClientsPage } from "./clients";
import { SettingsShortcutsPage } from "./settings/shortcuts";
import OrderPage from "./clients/order";
import { SettingsThemePage } from "./settings/themes";
import { ReceptionPage } from "./reception";
import { SupplierPage } from "./reception/supplier";
import { OngoingReceptionPage } from "./reception/ongoing";
import { SupplierRetourOneClickPage } from "./reception/supplier/retour/retour-one-click";
import { SupplierRetourMultipleProductPage } from "./reception/supplier/retour/retour-multi-products";
import { SupplierCommandOneClickPage } from "./reception/supplier/command/command-one-click";
import { ReceptionHeaderPage } from "./reception/header";
import { DashboardPage } from "./dashboard";
import { CustomerTriggerIcon } from "@views/_layout/customer";
import ReassortPage from "./fournisseur";
import { SupplierOrderPage } from "./commandes-fournisseur";
import { SupplierPrepOrderPage } from "./commandes-fournisseur/commandes-preparations";
import { SupplierSendOrderPage } from "./commandes-fournisseur/envoi-commandes";
import { SupplierSendOrderLinePage } from "./commandes-fournisseur/envoi-commandes-lignes";
import BottomPopUp from "@atoms/popup";
import { BaseBold } from "@atoms/text";
import { Button } from "@atoms/button/button";
import { useCheckVersion } from "@features/utils/use-check-version";

export const BackOfficeRoutes = () => {
  return (
    <Route element={<Layout />}>
      <Route path={ROUTES.Locations} element={<LocationsPage />} />
      <Route path={ROUTES.Home} element={<HomePage />} />
      <Route path={ROUTES.Products} element={<ProductsPage />} />
      <Route path={ROUTES.Product} element={<ProductPage />} />
      <Route path={ROUTES.Basket} element={<BasketPage />} />
      <Route path={ROUTES.BasketClientOrder} element={<CommandePage />} />
      <Route
        path={ROUTES.SettingsShortcuts}
        element={<SettingsShortcutsPage />}
      />
      <Route path={ROUTES.ThemePage} element={<SettingsThemePage />} />
      <Route path={ROUTES.Unavailable} element={<NotAvailablePage />} />
      <Route path={ROUTES.Clients} element={<ClientsPage />} />
      <Route path={ROUTES.Order} element={<OrderPage />} />
      <Route path={ROUTES.Receptions} element={<ReceptionPage />} />
      <Route path={ROUTES.SupplierPage} element={<SupplierPage />} />
      <Route
        path={ROUTES.OngoingReception}
        element={<OngoingReceptionPage />}
      />
      <Route
        path={ROUTES.SupplierRetourOne}
        element={<SupplierRetourOneClickPage />}
      />
      <Route
        path={ROUTES.SupplierRetourMulti}
        element={<SupplierRetourMultipleProductPage />}
      />
      <Route
        path={ROUTES.SupplierCommandOne}
        element={<SupplierCommandOneClickPage />}
      />
      <Route
        path={ROUTES.SupplierCommandMulti}
        element={<SupplierRetourMultipleProductPage />}
      />
      <Route
        path={ROUTES.ReceptionHeaderPage}
        element={<ReceptionHeaderPage />}
      />
      <Route path={ROUTES.Dashboard} element={<DashboardPage />} />
      <Route path={ROUTES.FournReassort} element={<ReassortPage />} />
      <Route path={ROUTES.SupplierOrderPage} element={<SupplierOrderPage />} />
      <Route
        path={ROUTES.SupplierPrepOrderPage}
        element={<SupplierPrepOrderPage />}
      />
      <Route
        path={ROUTES.SupplierSendOrderPage}
        element={<SupplierSendOrderPage />}
      />
      <Route
        path={ROUTES.SupplierSendOrderLinePage}
        element={<SupplierSendOrderLinePage />}
      />
    </Route>
  );
};

export const Layout = () => {
  const isUpdated = useCheckVersion();
  return (
    <div className="flex w-full grow flex-col bg-slate-50 dark:bg-slate-900 h-full">
      {window.self === window.top && <Header />}
      <div className="grow flex min-h-0">
        <div className="grow min-h-0 overflow-auto">
          <Outlet />
        </div>
      </div>
      <Modals />
      <ScanBox />
      {window.self === window.top && <CustomerTriggerIcon />}
      <ScanTriggerIcon />
      <Portals />
      {!isUpdated && (
        <BottomPopUp
          children={
            <div className="h-full flex flex-col gap-2">
              <BaseBold className="text-sm md:text-base">
                Une nouvelle version de l'application est disponible !
              </BaseBold>
              <Button
                onClick={() => {
                  window.location.href =
                    window.location.href.split("?")[0] +
                    `?cache_bust=${new Date().getTime()}`;
                }}
                className="self-center max-w-40"
              >
                Mettre à jour
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};
