import { LoadingState } from "@features/utils/store/loading-state-atom";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import { useRecoilCallback, useRecoilState } from "recoil";
import { ProductsApiClient } from "../api-client/api-client";
import {
  ProductType,
  SearchProductAdvancedRequestType,
  SearchProductsRequestType,
} from "../types";
import { ProductFiltersAtom, SearchProductsAtom } from "./store";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ProductFilters } from "@features/product-forms/product-filters";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

export const useProducts = () => {
  const [products] = useRecoilState(SearchProductsAtom);
  const [productFilters, setProductFilters] =
    useRecoilState(ProductFiltersAtom);
  const { current } = useShopLocations();

  const [loading, setLoading] = useRecoilState(
    LoadingState(["useProducts", false])
  );

  useControlledEffect(() => {
    let tempFilter: { [key: string]: any } = {};
    current?.items?.forEach((el) => {
      switch (el.modeGest) {
        case "L":
          tempFilter[el.codeTypeProd] = ProductFilters.book;
          return;
        case "N":
          tempFilter[el.codeTypeProd] = ProductFilters.book;
          return;
        case "D":
          tempFilter[el.codeTypeProd] = ProductFilters.record;
          return;
        case "S":
          tempFilter[el.codeTypeProd] = ProductFilters.press;
          return;
        default:
          tempFilter[el.codeTypeProd] = ProductFilters.paper;
          return;
      }
    });
    setProductFilters({ ...productFilters, ...tempFilter });
  }, [current]);
  const search = useRecoilCallback(
    ({ set }) =>
      async (
        search: SearchProductsRequestType | SearchProductAdvancedRequestType,
        advancedType: string,
        reset = false
      ) => {
        //Temporaire --> en attente de la prise en compte du type produit par le backend
        let endpointType = "";
        if (advancedType) {
          const modeGest =
            current?.items?.find((el) => el.codeTypeProd === advancedType)
              ?.modeGest || "P";
          endpointType = getRouteAdvancedSearch(modeGest);
        }

        try {
          setLoading(true);
          let result = advancedType
            ? await ProductsApiClient.getProductsAdvanced(
                search as SearchProductAdvancedRequestType,
                endpointType,
                advancedType
              )
            : await ProductsApiClient.getProducts(
                search as SearchProductsRequestType
              );
          if (search.disponible) {
            if (result.totalRows > 25) {
              result = await ProductsApiClient.getProductsAdvanced(
                {
                  ...search,
                  queryExtendedAtBd: false,
                  pageSize: 200,
                } as SearchProductAdvancedRequestType,
                endpointType,
                advancedType
              );
            }
          }
          set(SearchProductsAtom, (old) => {
            if (reset)
              return !search.disponible // TODO remove this when backend has implemented it
                ? result
                : {
                    ...result,
                    items: [...result.items].filter((p) => p.qteDispoVente > 0),
                    totalRows: [...result.items].filter(
                      (p) => p.qteDispoVente > 0
                    ).length,
                  };
            return { ...result, items: [...old.items, ...result.items] };
          });
        } catch (err) {
          toast.error(`${err}`);
        } finally {
          setLoading(false);
        }
      },
    [setLoading]
  );

  const debouncedGetSuggestions = debounce(
    async (
      query: { [key: string]: string | null },
      callback: (result: { label: string; value: string }[]) => void,
      options?: { aggregated?: boolean }
    ) => {
      if (Object.values(query)[0]?.trim()) {
        const result: { label: string; value: string; codeRech?: string }[] =
          options?.aggregated
            ? await ProductsApiClient.getAggregatedSuggestions(query)
            : await ProductsApiClient.getSuggestions(query);

        callback(result);
      } else {
        callback([]);
      }
    },
    500,
    { leading: true }
  );

  const getSuggestions = (query: { [key: string]: string | null }) => {
    return new Promise((resolve) => {
      debouncedGetSuggestions(query, resolve);
    }) as Promise<{ label: string; value: string }[]>;
  };

  const getAggregatedSuggestions = (query: {
    [key: string]: string | null;
  }) => {
    return new Promise((resolve) => {
      debouncedGetSuggestions(query, resolve, { aggregated: true });
    }) as Promise<{ label: string; value: string }[]>;
  };

  const handleFamilySugg = async (
    selectedField: string,
    current:
      | {
          label: string;
          value: string;
          codeRech: string;
        }[]
      | {
          label: string;
          value: string;
          codeRech: string;
        }
  ) => {
    if (current) {
      const l = Array.isArray(current)
        ? current[0].codeRech.split("<sep>")
        : current.codeRech.split("<sep>");
      let segment;
      let sousFamille;
      let famille;
      if (selectedField === "Segment") {
        const res = await ProductsApiClient.getSuggestions({
          SousFamille: l[1],
          ModeGestion: "P",
        });
        sousFamille = res.find((el) => el.value === l[1]);
        const resFam = await ProductsApiClient.getSuggestions({
          Famille: l[0],
          ModeGestion: "P",
        });
        famille = resFam.find((el) => el.value === l[0]);
        segment = Array.isArray(current) ? current[0] : current;
      }

      if (selectedField === "SousFamille") {
        const resFam = await ProductsApiClient.getSuggestions({
          Famille: l[0],
          ModeGestion: "P",
        });
        famille = resFam.find((el) => el.value === l[0]);
        sousFamille = Array.isArray(current) ? current[0] : current;
      }
      if (selectedField === "Famille") {
        return {
          famille: Array.isArray(current) ? current[0] : current,
          sousFamille: undefined,
          segment: undefined,
        };
      }
      return { famille: famille, sousFamille: sousFamille, segment: segment };
    }
  };

  const regulStock = async (
    product: ProductType,
    codeLieu: string,
    qteRegul: number,
    codeMotifRegul: string,
    libRegul: string
  ) => {
    setLoading(true);
    try {
      await ProductsApiClient.regulStock(
        product,
        codeLieu,
        qteRegul,
        codeMotifRegul,
        libRegul
      );
      setLoading(false);
      toast.success("Stock mis à jour");
      return true;
    } catch {
      toast.error("Impossible de mettre à jour le stock");
      setLoading(false);
      return false;
    }
  };

  const getProductScan = async (productID: string, forceRef?: boolean) => {
    setLoading(true);
    const resultSearch = await ProductsApiClient.getProductScan(productID);
    if (resultSearch[0]) {
      if (await ProductsApiClient.referenceProduct(resultSearch[0].id)) {
        setLoading(false);
        return resultSearch[0];
      } else {
        toast.error("Problème de référencement");
        setLoading(false);
        return null;
      }
    }
    if (forceRef) {
      const resultSearchBis = await ProductsApiClient.getProducts({
        query: productID,
        queryExtendedAtBd: true,
      });
      if (resultSearchBis.items[0]) {
        if (
          await ProductsApiClient.referenceProduct(resultSearchBis.items[0].id)
        ) {
          return resultSearchBis.items[0];
        } else {
          toast.error("Problème de référencement");
          return null;
        }
      }
    }
    setLoading(false);
    toast.error("Article non trouvé");
    return null;
  };

  //Temporaire --> en attente de la prise en compte du type produit par le backend

  const etiqProducts = async (products: ProductType[]) => {
    setLoading(true);
    try {
      products.map(async (product) => {
        if (!product.referGestion)
          await ProductsApiClient.referenceProduct(product.id);
      });

      await ProductsApiClient.productLabel(
        products.map((product) => {
          return {
            ean13: product.id,
            codeCanalVente:
              product.listPlcv.length === 1
                ? product.listPlcv[0].codeCanalVente
                : product.listPlcv.find((el) => el.princ === true)!
                    .codeCanalVente,
          };
        })
      );
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return {
    products,
    productFilters,
    loading,
    getSuggestions,
    getAggregatedSuggestions,
    search,
    regulStock,
    getProductScan,
    handleFamilySugg,
    etiqProducts,
  };
};

export const getRouteAdvancedSearch = (modeGest: string) => {
  switch (modeGest) {
    case "L":
      return "book";
    case "N":
      return "ebook";
    case "D":
      return "record";
    case "S":
      return "press";
    default:
      return "paper";
  }
};
